import Image from 'next/image';
import NextLink from 'next/link';

import { Link, Tabs } from '@/atoms';
import { WhatsIncludedGrid } from '@/molecules';

import type { FunctionComponent } from 'react';
import type { TypeWhatsIncludedProps } from './types';

/**
 * WhatsIncluded
 */
export const WhatsIncluded: FunctionComponent<TypeWhatsIncludedProps> = ({
  className = '',
  elements,
  image,
  nElements,
  tabsName,
  type,
  variant,
  footer,
  link,
  selectedAtFirst = 1,
  numberOfColumns,
}: TypeWhatsIncludedProps) => (
  <>
    <div className={`flex flex-row gap-6 ${className}`}>
      <div className={`flex flex-col overflow-hidden ${image ? '' : 'w-full'}`}>
        {tabsName?.length > 1 ? (
          <Tabs
            tabsName={tabsName}
            type={type}
            selectedAtFirst={selectedAtFirst}
          >
            {elements?.map((child, index) => (
              <WhatsIncludedGrid
                key={index}
                elements={child.elemntTab}
                numberOfElements={nElements}
                variant={variant}
                className="mt-10"
                numberOfColumns={numberOfColumns}
              />
            ))}
          </Tabs>
        ) : (
          <WhatsIncludedGrid
            elements={elements?.at(0)?.elemntTab ?? []}
            numberOfElements={nElements}
            variant={variant}
            numberOfColumns={numberOfColumns}
          />
        )}

        {link?.src && link?.label && (
          <NextLink href={link.src} passHref prefetch={false}>
            <Link className="ml-auto mt-8" arrow={true} variant={variant}>
              {link.label}
            </Link>
          </NextLink>
        )}
      </div>

      {image && (
        <div className="mt-[76px] hidden min-w-[350px] lg:flex">
          <div className="relative h-[500px] w-[350px]">
            <Image
              src={image}
              layout="fill"
              objectFit="cover"
              alt="iamgen que incluye"
            />
          </div>
        </div>
      )}
    </div>
    {footer && (
      <div className="text-body--secondary flex w-full gap-4 text-primary-300 sm:flex-row md:w-1/2">
        {footer}
      </div>
    )}
  </>
);

WhatsIncluded.displayName = 'WhatsIncluded';
