import { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';

import {
  Button,
  ConditionalWrapper,
  Grid,
  WhatsIncludedElement,
} from '@/atoms';

import Arrow from '@/assets/icons/ArrowUPPri.svg';

import type { FunctionComponent } from 'react';
import type { TypeWhatsIncludedGridProps } from './types';

/**
 * WhatsIncludedGrid
 */
export const WhatsIncludedGrid: FunctionComponent<
  TypeWhatsIncludedGridProps
> = ({
  className,
  elements = [],
  variant,
  numberOfElements,
  numberOfColumns,
}: TypeWhatsIncludedGridProps) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation('common');

  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  /* @ts-ignore */
  const labels: { viewMore: string; viewLess: string } = t(
    'components.atoms.seeMore.labels',
    'common',
    { returnObjects: true }
  );

  return (
    <ConditionalWrapper
      condition={elements.length > numberOfElements}
      wrapper={(children) => (
        <div className="flex flex-col items-center justify-center gap-6 md:gap-10">
          {children}
        </div>
      )}
    >
      <Grid
        xs={numberOfColumns?.mobile || 2}
        md={numberOfColumns?.tablet || 2}
        lg={numberOfColumns?.desktop || 3}
        outline={false}
        className={`gap-7 gap-y-10 ${className}`}
      >
        {elements.map((item, index) => (
          <Grid.Item
            key={index}
            className={
              index + 1 > numberOfElements && !expanded ? 'hidden' : ''
            }
          >
            <WhatsIncludedElement
              icon={item.icon}
              title={item.title}
              description={item.description}
              variant={variant}
              key={index}
            />
          </Grid.Item>
        ))}
      </Grid>

      {elements.length > numberOfElements && (
        <Button
          variant="outline"
          size="large"
          className="flex-shrink-0 items-center self-start"
          onClick={() => setExpanded((prev) => !prev)}
        >
          {expanded ? labels?.viewLess : labels?.viewMore}
          <Arrow
            width={16}
            height={16}
            className={`transition-all duration-500 ${
              expanded ? 'rotate-0' : 'rotate-180'
            }`}
          />
        </Button>
      )}
    </ConditionalWrapper>
  );
};

WhatsIncludedGrid.displayName = 'WhatsIncludedGrid';
