import { oneLine } from 'common-tags';

import { Icon } from '@/atoms';

import type { TypeTextColorVariant } from '@/components/types';
import type { FunctionComponent } from 'react';
import type { TypeWhatsIncludedElementProps } from './types';

const VARIANT_MAPS: Record<TypeTextColorVariant, string> = {
  primary: 'text-primary-400',
  secondary: 'text-secondary-400',
  alternative: 'text-white',
};

/**
 * WhatsIncludedElement
 */
export const WhatsIncludedElement: FunctionComponent<
  TypeWhatsIncludedElementProps
> = ({
  className = '',
  description = '',
  icon = '',
  title = '',
  variant,
}: TypeWhatsIncludedElementProps) => (
  <div
    className={oneLine`
      text-body--primary
      md:min-w-[210px]
      ${VARIANT_MAPS[variant]}
      ${className}
    `}
  >
    <Icon
      src={icon}
      className={`h-8 w-8 md:h-[50px] md:w-[50px] ${VARIANT_MAPS[variant]}`}
    />

    <h3 className="text-subtitle text-subtitle--small  py-2 font-bold md:text-subtitle--large">
      {title}
    </h3>

    <p className="text-body text-body--secondary md:text-body--primary">
      {description}
    </p>
  </div>
);

WhatsIncludedElement.displayName = 'WhatsIncludedElement';
