import { Container, SectionText } from '@/atoms';
import { WhatsIncluded } from '@/organisms';

import type { FunctionComponent } from 'react';
import type { TypeWhatsIncludedTemplateProps } from './types';

/**
 * WhatsIncludedTemplate
 */
export const WhatsIncludedTemplate: FunctionComponent<
  TypeWhatsIncludedTemplateProps
> = ({
  className = '',
  backgroundColorBottom,
  backgroundColorBottomMobile,
  backgroundColorTop,
  backgroundColorTopMobile,
  caption,
  cutLevel = 50,
  cutLevelMobile = 50,
  desc,
  footer,
  textColor,
  title,
  whatsIncludedProps,
  index,
  id,
}: TypeWhatsIncludedTemplateProps) => (
  <Container
    index={index}
    backgroundColorBottom={backgroundColorBottom}
    backgroundColorTopMobile={backgroundColorTopMobile}
    backgroundColorTop={backgroundColorTop}
    backgroundColorBottomMobile={backgroundColorBottomMobile}
    cutLevel={cutLevel === null ? 50 : cutLevel}
    cutLevelMobile={cutLevelMobile === null ? 50 : cutLevel}
    className={`flex flex-col gap-6 md:gap-10 ${className}`}
    id={id}
  >
    <SectionText
      title={title}
      desc={desc}
      direction="row"
      textAlign="start"
      variant={textColor}
      caption={caption}
    />
    <WhatsIncluded
      {...whatsIncludedProps}
      footer={footer}
      variant={textColor}
    />
  </Container>
);

WhatsIncludedTemplate.displayName = 'WhatsIncludedTemplate';
